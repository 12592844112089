:root {
  --themeColor: #f86d64;
  --textColor: #515151;
  --bannerButtonColor: #f86d64;
  --bannerButtonColorSecondary: #f86d64;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  display: flex;
}

.slick-dots {
  width: auto;
}

.slick-dots li button:before {
  display: none;
}

.slick-slider {
  overflow: hidden;
}

.main-aside .aside-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem .9375rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.social-location {
  padding-left: .9375rem;
  padding-right: .9375rem;
  margin-top: 1.875rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.6rem;
  text-align: center;
}

.social-location a {
  color: var(--textColor);
}

.social-media-border {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.social-media-border:before {
  content: '';
  display: block;
  border-bottom: 1px solid var(--themeColor);
  margin-bottom: 1.875rem;
}

:focus {
  outline: none;
}

.cart-open.cart-sidebar {
  right: 0;
}

.cart-open+.cart-sidebar-overlay {
  opacity: 1;
  visibility: visible;
}

.slick-current .banner-nav-item .banner-nav-item-inner {
  background-color: #fccc4c;
}

.banner-nav-item-inner .banner-nav-item-body span {
  text-decoration: none;
  color: var(--textColor);
}

.slick-current .banner-nav-item .banner-nav-item-inner h5,
.slick-current .banner-nav-item .banner-nav-item-inner span,
.slick-current .banner-nav-item .banner-nav-item-inner .banner-nav-item-body span {
  color: #fff;
}

.slick-current .banner-nav-item .banner-nav-item-inner .banner-nav-item-body span+span,
.banner-nav-item-inner .banner-nav-item-body span+span {
  text-decoration: line-through;
  color: #b9b9b9;
  margin-left: 10px;
}

.close {
  border: 0;
  background-color: transparent;
  padding: 0;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.close:hover {
  background-color: transparent !important;
}

.close:focus {
  outline: none;
}

.close span {
  position: absolute;
  width: 2px;
  height: 15px;
  display: block;
  background-color: var(--textColor);
  opacity: 0.6;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 0;
}

.close span.sr-only {
  clip: unset;
}

.close.close-dark span {
  background-color: #1d2228;
}

.close.close-danger span {
  background-color: red;
}

.close span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close:hover span {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.close:hover span:nth-child(2) {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.close.dark span {
  background-color: #1d2228;
}

.modal-header .close {
  margin: 0;
  opacity: 1;
  padding: 0;
}

.modal-header.modal-bg .close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal-header.modal-bg {
  background-size: contain;
  background-repeat: no-repeat;
}

.banner.banner-3 .slick-current .banner-item .banner-text {
  opacity: 1;
  visibility: visible;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar a {
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.sidebar-widget .media>a {
  height: 90px;
}

.sidebar-widget .media>a>img {
  height: 100%;
  object-fit: cover;
}

.pagination .page-item.active .page-link:hover,
.pagination .page-item.active .page-link:focus {
  color: #fff;
}

.ct-notice .nav-link {
  font-weight: normal;
  background-color: transparent;
  color: #ed4e53;
  margin-right: 0;
  display: inline-block;
  border-radius: 0;
  margin-left: 5px;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-item {
  margin-bottom: 0px;
}

.nav-tabs .nav-link {
  border: 0px solid transparent;
  padding: 10px 20px;
  background-color: #f9f9f9;
  font-weight: 600;
  margin-right: 10px;
  border-radius: 8px;
  color: #1d2228;
}

.nav-tabs .nav-link.active {
  background-color: #ed4e53;
  color: #fff;
}

/* Accordion */
.accordion .card {
  display: flex;
  flex-wrap: wrap;
}

.accordion .card .collapseparent {
  order: 2;
}

.accordion .card .card-header {
  width: 100%;
  flex-basis: 100%;
  order: 1;
  padding: 0;
  border-radius: 0;
}

.accordion .card .card-header .nav-link {
  padding: 15px 20px;
  background: transparent;
  display: flex;
  width: 100%;
  font-weight: 700;
  color: #1d2228;
  border-radius: 0;
  margin-right: 0;
}

.accordion .card .collapseparent.show+.card-header {
  background-color: #f8f9fa;
  color: #1d2228;
}

.accordion .card .collapseparent.show+.card-header:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 0;
}

.accordion .card .collapseparent.show+.card-header:before,
.accordion .card .collapseparent.show+.card-header:after {
  background-color: #1d2228;
}

.accordion .card .collapseparent.show+.card-header .nav-link i {
  color: #ed4e53;
}



.btn-check-our-menu {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  /* background-color: #ed4e53; */
  line-height: 24px;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 25px;
  z-index: 1;
  /* -webkit-box-shadow: 4px 3px 24px rgba(237, 78, 83, 0.5); */
  /* box-shadow: 4px 3px 24px rgba(237, 78, 83, 0.5); */
}

.btn-check-our-menu::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--themeColor);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 25px;
  z-index: -1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.btn-check-our-menu:hover::before,
.btn-check-our-menu:focus::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1.1);
}

.btn-check-our-menu:hover,
.btn-check-our-menu:focus {
  color: #fff;
  outline: none;
}

.food_logo_link {
  padding-right: 20px;
  line-height: 60px;
}

.uber-eats {
  height: 3em;
  width: auto;
}

.skip-the-dishes {
  height: 3.25em;
  width: auto;
}

.door-dash {
  height: 1.75em;
  width: auto;
}

.ritual {
  height: 3em;
  width: auto;
  padding-right: 1em;
}

.fantuan-delivery {
  height: 1.75em;
  width: auto;
}

.mealpal {
  height: 4em;
  width: auto;
}

.fod {
  height: 1.65em;
  width: auto;
}

.food-highway {
  height: 1.75em;
  width: auto;
}

.grub-hub {
  height: 1.75em;
  width: auto;
}

.post-mates {
  height: 1.75em;
  width: auto;
}

.deliveroo {
  height: 1.75em;
  width: auto;
}

.slide-menu-footer-text {
  position: fixed;
  bottom: 0;
  width:100%; 
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.6rem;
  text-align: center;
}

.slide-menu-footer-text a {
  color: var(--textColor);
}